export const searchAjax = {
  affiliateId: 220703,
  actionType: "institution",
  keyword: "leeds",
  qualCode: "M",
  networkId: 2,
};

export const reviewPayload = {
  affiliateId: 220703,
};

export const ucasAjax = {
  affiliateId: 220703,
  qualID: null,
};
